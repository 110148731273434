var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" รายการจอง "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"footer-props":_vm.footer,"no-data-text":"ไม่พบข้อมูล","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMiniDate")(item.createdOn))+" ")]}},{key:"item.schedule.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMiniDate")(item.schedule.date))+" ")]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.branch === "trang" ? "ตรัง" : "สาทร")+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isGroup ? "กลุ่ม" : "ปกติ")+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.price.total))+" ")]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.orderStatus)},[_vm._v(" "+_vm._s(_vm._f("formatStatus")(item.orderStatus))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }