<template>
  <v-card class="mt-5">
    <v-card-title>
      รายการจอง
      <v-spacer></v-spacer>

      <!-- <v-select
        v-model="typeSelected"
        label="ประเภทการจอง"
        outlined
        :items="typeItems"
      ></v-select>
      <v-spacer></v-spacer>
      <v-select
        v-model="statusSelected"
        label="สถานะ"
        outlined
        :items="statusItems"
      ></v-select> -->
    </v-card-title>
    <v-data-table
      :footer-props="footer"
      no-data-text="ไม่พบข้อมูล"
      :headers="headers"
      :items="items"
    >
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.createdOn="{ item }">
        {{ item.createdOn | formatMiniDate }}
      </template>
      <template v-slot:item.schedule.date="{ item }">
        {{ item.schedule.date | formatMiniDate }}
      </template>
      <template v-slot:item.branch="{ item }">
        {{ item.branch === "trang" ? "ตรัง" : "สาทร" }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.isGroup ? "กลุ่ม" : "ปกติ" }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price.total) }}
      </template>
      <template v-slot:item.orderStatus="{ item }">
        <span :class="statusColor(item.orderStatus)">
          {{ item.orderStatus | formatStatus }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    order: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusSelected: "paid",
      typeSelected: "normal",
    };
  },
  computed: {
    statusItems() {
      return [
        { text: "ชำระเงินแล้ว", value: "paid" },
        { text: "รอชำระเงิน", value: "waiting" },
      ];
    },
    typeItems() {
      return [
        { text: "ทั่วไป", value: "normal" },
        { text: "กลุ่ม", value: "group" },
      ];
    },
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
    headers() {
      return [
        {
          text: "ลำดับที่",
          align: "center",
          value: "index",
        },
        {
          text: "วันที่ทำรายการ",
          align: "left",
          value: "createdOn",
        },
        {
          text: "วันที่จอง",
          align: "left",
          value: "schedule.date",
        },
        {
          text: "หมายเลขรายการจอง",
          align: "left",
          value: "orderId",
        },
        {
          text: "สาขา",
          value: "branch",
        },
        {
          text: "ประเภทการจอง",
          value: "type",
          align: "center",
        },
        {
          text: "จำนวน",
          value: "joinQty",
        },
        {
          text: "ราคา",
          value: "price",
        },
        {
          text: "สถานะ",
          value: "orderStatus",
        },
      ];
    },
    items() {
      return this.order;
    },
  },
  methods: {
    statusColor(value) {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
    submit(memberId, member) {
      const payload = {
        memberId,
        member,
      };
      this.$emit("input", payload);
    },
  },
};
</script>
