<template>
  <div>
    <Loading v-if="loading" />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <h2 class="text-secondary header">รายงานรายลูกค้า</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="mt-6">
      <v-col cols="12">
        <v-select
          @change="getOrderByMemberId()"
          :items="branchItems"
          item-text="text"
          item-value="value"
          outlined
          v-model="branch"
          label="สาขา"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <PersonalizeDataTable
          :member="members"
          :totalPages="pagination.totalPages"
          :page="pagination.page"
          @export="exportOrder()"
          @date="onRangeDate($event)"
          @input="getOrderByMemberId($event)"
          @search="getMember($event)"
          @paginationPage="paginationPage($event)"
        />
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col cols="12" md="4">
          <PersonalCard v-if="reload" :member="member" />
        </v-col>
        <v-col cols="12" md="8">
          <OrderDetailCard v-if="reload" :order="order" />
        </v-col>
      </v-row>
      <v-row>
        <v-col ref="member" cols="12">
          <OrderDataTable :order="order" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end mr-1 my-6">
        <JsonCSV
          :data="exportJson.data"
          :labels="exportJson.labels"
          :name="name + '.csv'"
        >
          <v-btn block class="font-weight-bold" dark color="green"
            >ส่งออก CSV</v-btn
          >
        </JsonCSV>
      </v-row>
      <!-- <ExportButton class="mt-10" /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@lottiefiles/lottie-player";
import JsonCSV from "vue-json-csv";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import Loading from "@/components/Loading";
import PersonalizeDataTable from "@/components/Report/Personalized/PersonalizeDataTable";
import OrderDataTable from "@/components/Report/Personalized/OrderDataTable";
import PersonalCard from "@/components/Report/Personalized/PersonalCard";
import OrderDetailCard from "@/components/Report/Personalized/OrderDetailCard";
// import ExportButton from "@/components/Report/ExportButton";
export default {
  components: {
    Loading,
    JsonCSV,
    PersonalizeDataTable,
    OrderDataTable,
    PersonalCard,
    OrderDetailCard,
    // ExportButton,
  },
  data() {
    return {
      loading: false,
      reload: false,
      branch: "",
      storesEvent: "",
      date: [
        dayjs(new Date()).format("YYYY-MM-DD"),
        dayjs(new Date()).format("YYYY-MM-DD"),
      ],
      member: {},
      members: [],
      order: [],
      pagination: {
        page: 1,
        totalPages: 1,
        search: "",
      },
      exportJson: {
        labels: {},
        data: [],
      },
    };
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    this.getMember();
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    name() {
      return this.member.firstname + "_" + this.member.lastname;
    },
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  methods: {
    async getMember(event) {
      try {
        this.pagination.search = event || "";
        const {
          data: { data },
        } = await axios.get(
          `/member?page=${this.pagination.page}&search=${this.pagination.search}&limit=5`
        );
        this.members = data.docs;
        this.pagination.totalPages = data.totalPages;
      } catch (error) {
        console.error(error);
      }
    },
    paginationPage(val) {
      this.pagination.page = val;
      this.getMember();
    },
    async getOrderByMemberId(event) {
      try {
        if (event) {
          this.storesEvent = event;
        }
        const arr = [];
        const {
          data: { data },
        } = await axios.get(
          `/report/personalize?memberId=${this.storesEvent.memberId}&startDate=${this.date[0]}&endDate=${this.date[1]}&branch=${this.branch}`
        );
        this.exportJson.labels = {
          createdOn: "วันที่ทำรายการ",
          scheduleDate: "วันที่จอง",
          orderId: "หมายเลขการจอง",
          branch: "สาขา",
          type: "ประเภท",
          joinQty: "จำนวนคน",
          price: "ราคา",
          orderStatus: "สถานะ",
        };
        for (let i = 0; i < data.length; i++) {
          arr.push({
            createdOn: dayjs(data[i].createdOn).format("DD/MM/YYYY"),
            scheduleDate: dayjs(data[i].schedule.date).format("DD/MM/YYYY"),
            orderId: data[i].orderId,
            branch: data[i].branch === "trang" ? "ตรัง" : "สาทร",
            type:
              data[i].type === "group"
                ? "กลุ่ม"
                : data[i].type === "event"
                ? "กิจกรรม"
                : "ปกติ",
            joinQty: data[i].joinQty,
            price: data[i].price.total,
            orderStatus:
              data[i].orderStatus === "waiting"
                ? "รอดำเนินการ"
                : "ดำเนินการสำเร็จ",
          });
        }
        const result = arr.sort((a, b) => {
          return a.createdOn < b.createdOn ? -1 : 1;
        });
        this.exportJson.data = result;
        this.member = this.storesEvent.member;
        this.order = data;
        this.reload = true;
        const el = this.$refs.member;
        setTimeout(() => {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 200);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    exportOrder() {},
    onRangeDate(event) {
      this.date = event;
    },
  },
};
</script>
