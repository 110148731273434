<template>
  <v-card>
    <v-card-title>
      <span>ข้อมูลส่วนตัว</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <span>ชื่อจริง - นามสกุล:</span>
        </v-col>
        <v-col cols="7">
          <span>{{ name }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <span>อายุ :</span>
        </v-col>
        <v-col cols="10">
          <span>{{ age }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <span>ที่อยู่: </span>
        </v-col>
        <v-col cols="10">
          <span>{{ address }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    member: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    name() {
      if (this.member.firstname && this.member.lastname) {
        return this.member.firstname + " " + this.member.lastname;
      }
      return "";
    },
    age() {
      if (this.member.birthday) {
        return dayjs().diff(dayjs(this.member.birthday), "year");
      }
      return "";
    },
    address() {
      if (this.member.address) {
        const adr = this.member.address;
        return `${adr.subDistrict} ${adr.district} ${adr.province} ${adr.postcode}`;
      }
      return "";
    },
  },
};
</script>
