<template>
  <v-card>
    <v-card-title>
      <span>รายละเอียด</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <span>จองทั้งหมด: {{ totalBooking }}</span>
        </v-col>
        <v-col cols="3">
          <span>จองปกติ: {{ normalBooking }}</span>
        </v-col>
        <v-col cols="3">
          <span>จองกลุ่ม: {{ groupBooking }}</span>
        </v-col>
        <v-col cols="3"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span>รายการรอชำระเงิน: {{ waitingOrder }}</span>
        </v-col>
        <v-col cols="3">
          <span>รายการที่จ่ายแล้ว: {{ paidedOrder }}</span>
        </v-col>
        <v-col cols="3">
          <span>รายการที่ถูกปฏิเสธ: {{ rejectedOrder }}</span>
        </v-col>
        <v-col cols="3">
          <span>รายการที่ถูกยกเลิก: {{ canceledOrder }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span
            >ยอดเงินรวม (รายการที่จ่ายแล้ว):
            {{ formatCurrency(totalPrice) }} บาท</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    order: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalBooking() {
      return this.order.length;
    },
    normalBooking() {
      return this.order.filter((item) => !item.isGroup).length;
    },
    groupBooking() {
      return this.order.filter((item) => item.isGroup).length;
    },
    waitingOrder() {
      return this.order.filter((item) => item.orderStatus === "waiting").length;
    },
    paidedOrder() {
      return this.order.filter((item) => item.orderStatus === "paid").length;
    },
    rejectedOrder() {
      return this.order.filter((item) => item.orderStatus === "rejected")
        .length;
    },
    canceledOrder() {
      return this.order.filter((item) => item.orderStatus === "canceled")
        .length;
    },
    totalPrice() {
      const order = this.order;
      if (order) {
        let sum = 0;
        const paidOrder = order.filter((item) => item.orderStatus === "paid");
        for (let i = 0; i < paidOrder.length; i++) {
          if (paidOrder[i].price) sum += paidOrder[i].price.total;
        }
        return sum;
      }
      return 0;
    },
  },
};
</script>
