<template>
  <v-card class="mt-5">
    <v-card-title>
      เลือกรายชื่อลูกค้า
      <v-spacer></v-spacer>
      <DatePcikerRange
        startLabel="เริ่มต้น (วันทำรายการ)"
        endLabel="สิ้นสุด (วันทำรายการ)"
        @input="onRangeDate($event)"
      />
    </v-card-title>

    <v-row class="px-4 mb-4">
      <v-col cols="12">
        <v-text-field
          @keypress.enter="searchMember()"
          @click:clear="clearSearch()"
          class="mt-4"
          v-model="search"
          label="ค้นหา"
          clearable
          outlined
          prepend-inner-icon="mdi-account-search-outline"
          hint="กด Enter เพื่อค้นหา"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      no-data-text="ไม่พบข้อมูล"
      :footer-props="footer"
      :headers="headers"
      :items="items"
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="submit(item.memberId, item)" color="primary" dark
          >เลือก</v-btn
        >
      </template>
    </v-data-table>
    <div class="d-flex justify-end mt-8">
      <v-pagination
        class="mb-4"
        v-model="pages"
        color="primary"
        :length="totalPages"
        :total-visible="7"
        @input="paginationPage($event)"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import DatePcikerRange from "@/components/common/DatePickerRange.vue";
export default {
  props: {
    member: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.pages = this.page;
  },
  data() {
    return {
      search: "",
      pages: 1,
    };
  },
  components: {
    DatePcikerRange,
  },
  computed: {
    headers() {
      return [
        {
          text: "ชื่อ",
          value: "firstname",
        },
        {
          text: "นามสกุล",
          value: "lastname",
        },
        {
          text: "อีเมล",
          value: "email",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
    items() {
      return this.member;
    },
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
  },

  methods: {
    submit(memberId, member) {
      const payload = {
        memberId,
        member,
      };
      this.$emit("input", payload);
    },
    clearSearch() {
      this.search = "";
      this.searchMember();
    },
    onRangeDate(payload) {
      this.$emit("date", payload);
    },
    searchMember() {
      this.$emit("search", this.search);
    },
    paginationPage(value) {
      this.$emit("paginationPage", value);
    },
  },
};
</script>
